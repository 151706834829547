<template>
  <v-app class="app-container">
    <v-container fluid style="padding:0px 12px 0px 0px;">
      <v-row
        class="w-100"
        style="padding:15px;"
        align="center"
        justify="center"
        no-gutters
        v-for="(item, itemIndex) in allergens"
        v-bind:key="itemIndex"
      >
        <menu-item :title="item.title" :alergen="item.label" :description="item.description"></menu-item>
      </v-row>
      <div class="primary footer">
        <div class="footer-content">
          <a class="footer-link" href="https://digimeni.si/" target="blank">Digimeni</a> &copy;
          <a
            class="footer-link"
            href="https://foreach-labs.si/"
            target="blank"
          >Foreach Labs 2020</a>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  props: ["lang"],
  components: {
    "menu-item": () => import("../meni/MenuItem"),
  },
  computed: {
    allergens() {
      return this.$store.state.allergens[this.lang];
    },
  },
};
</script>

<style>
.hr-custom {
  border-top: 1px solid #e8e8e8;
}
.large-image {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.header-title {
  padding-top: 32px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  color: white;
  z-index: 1;
}

.footer-content {
  margin: auto;
  text-align: center;
  height: 100%;
  vertical-align: middle;
  font-size: 16px;
  margin-top: 25px;
}

.footer-link {
  color: white !important;
  text-decoration: none;
}
</style>